import React, { useContext } from 'react'
import  './Navbar.css'
import { langContext } from './../context/langContext';
import Resume from './Resume';


const Intro = () => {

    const idioma = useContext(langContext)
    return (
        <nav className="navbar navbar-light bg-transparent">
            <div className="navbar-top container-fluid bg-transparent">
                <div className="d-flex justify-content-start navbar-name">
                    <a className="navbar-brand" href="/">
                        <p className="logo-title">Allan Montilla</p>
                    </a>
                </div>
                <div className="d-flex justify-content-end navbar-flag">
                    <div >
                        <button className="button-flag" onClick={()=> idioma.establecerLenguaje('es-ES')}>
                            <img className="img-fluid px-2"  alt="Foto de Allan Montilla" src="/assets/images/espana.png" height="40" width="40"/>
                        </button>
                        <button className="button-flag" onClick={()=> idioma.establecerLenguaje('en-US')}>
                            <img className="img-fluid px-2"  alt="Foto de Allan Montilla" src="/assets/images/reino-unido.png" height="40" width="40"/>
                        </button>
                    </div>
                    <div className="p-0">
                        <Resume/>
                    </div>
                </div>
            </div>
        </nav>
    );
  };
  
  export default Intro;

