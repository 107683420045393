import React from 'react';


const Details = () => {
  return (
    <div className="pt-3 details">
        <div className="det-address my-4">
                <img className="img-fluid d-inline"  alt="Ubicación donde se encuentra Allan" src="/assets/images/address.png" height="25" width="25"/>
                <p className="d-inline" > Panamá, Panamá</p>
        </div>
        <div className="det-email my-4">
            <a  href="mailto:allangmr10@gmail.com" className="links">
                <img className="img-fluid d-inline "  alt=" Correo de Allan" src="/assets/images/email.png" height="25" width="25"/>
            </a>
            <p className="d-inline" > allangmr10@gmail.com</p>
        </div>
        <div className="det-repo my-4">
            <a  href="https://github.com/allangmr" target="_blank" rel="noreferrer" className="links">
                <img className="img-fluid d-inline"  alt="Repositorio de Allan" src="/assets/images/github.png" height="25" width="25"/>
                <p className="d-inline" >  github.com/allangmr</p>
            </a>
        </div>                    
    </div>

  );
};

export default Details;