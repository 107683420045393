import React from "react";
import { FormattedMessage } from "react-intl";
import Stack from "../about/Stack";
import "./ImageDesc.css";
const ImageDesc = (props) => {
  const images_stack = [
    {
      id: 1,
      link: "/assets/images/stack/html5.png",
      alt: "Domino la tecnología HTML5",
    },
    {
      id: 2,
      link: "/assets/images/stack/css3.png",
      alt: "Domino la tecnología CSS",
    },
    {
      id: 3,
      link: "/assets/images/stack/bootstrap.png",
      alt: "Domino la tecnología Bootstrap",
    },
    {
      id: 5,
      link: "/assets/images/stack/javascript.jpeg",
      alt: "Domino la tecnología Javascript",
    },
    {
      id: 7,
      link: "/assets/images/stack/vue.png",
      alt: "Domino la tecnología Vuejs",
    },
    {
      id: 8,
      link: "/assets/images/stack/php.jpg",
      alt: "Domino la tecnología php",
    },
    {
      id: 9,
      link: "/assets/images/stack/laravel.jpg",
      alt: "Domino la tecnología laravel",
    },
    {
      id: 13,
      link: "/assets/images/stack/mariadb.png",
      alt: "Domino la tecnología mariadb",
    },
    {
      id: 17,
      link: "/assets/images/stack/digitalocean.jpg",
      alt: "Domino la tecnología digitalocean",
    },
    {
      id: 18,
      link: "/assets/images/stack/postman.png",
      alt: "Domino la tecnología postman",
    },
    {
      id: 19,
      link: "/assets/images/stack/github.png",
      alt: "Domino la tecnología github",
    },
    {
      id: 20,
      link: "/assets/images/stack/git.png",
      alt: "Domino la tecnología git",
    },
    {
      id: 23,
      link: "/assets/images/stack/ubuntu.jpg",
      alt: "Domino la tecnología ubuntu",
    },
  ];
  const images = props.images;
  const listItems = images.map((image) => (
    <div className="col-lg-8 col-md-8 col-sm-12 mx-auto image" key={image.id}>
      <img
        src={image.link}
        alt={image.alt}
        width="1200"
        className="proyects_img img-fluid"
        key={image.id}
      />
    </div>
  ));
  return (
    <>
      <div className="row py-3">
        {listItems}
        <div className="col-lg-4 col-md-4 col-sm-12 description">
          <div className="description-text">
            <p>
              <span className="fw-bold">
                <FormattedMessage
                  id="proyects.name.title"
                  defaultMessage="Project Name: "
                />
              </span>
              <FormattedMessage
                id="proyects.name.msg"
                defaultMessage="Internal Messaging Platform and Training"
              />
            </p>
            <p>
              <span className="fw-bold">
                <FormattedMessage
                  id="proyects.desc.title"
                  defaultMessage="Project Description: "
                />
              </span>
              <FormattedMessage
                id="proyects.desc.msg"
                defaultMessage="This project includes the development of an internal messaging and training system, which allows the sharing of videos, images and other multimedia content."
              />
            </p>
          </div>
          <Stack images={images_stack} width="30" height="30" />
          <div className="row py-5 view_site footer">
            <div className="col-lg-12 col-md-12 col-sm-6 col-6 text-center">
              {/* <a
                                className="button is-primary is-outlined "
                                href="https://softwarepanama.com/"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    fill="currentColor"
                                    className="bi bi-box-arrow-up-right"
                                    viewBox="0 0 20 20"
                                >
                                    <path
                                        fillRule="evenodd"
                                        d="M8.636 3.5a.5.5 0 0 0-.5-.5H1.5A1.5 1.5 0 0 0 0 4.5v10A1.5 1.5 0 0 0 1.5 16h10a1.5 1.5 0 0 0 1.5-1.5V7.864a.5.5 0 0 0-1 0V14.5a.5.5 0 0 1-.5.5h-10a.5.5 0 0 1-.5-.5v-10a.5.5 0 0 1 .5-.5h6.636a.5.5 0 0 0 .5-.5z"
                                    />
                                    <path
                                        fillRule="evenodd"
                                        d="M16 .5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793L6.146 9.146a.5.5 0 1 0 .708.708L15 1.707V5.5a.5.5 0 0 0 1 0v-5z"
                                    />
                                </svg> */}
              {/* <FormattedMessage id="proyects.site"  defaultMessage=" Show Site"/> */}
              {/* </a> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ImageDesc;
