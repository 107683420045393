import React from 'react';
import { FormattedMessage } from 'react-intl'

const Career = () => {
  return (
    <div className="col-lg-6 col-md-6 col-sm-12  text-lg-center career">
        <h2 className="py-3 subtitle">
           <FormattedMessage id="about.welcome" defaultMessage="Hi, I’m Allan. Nice to meet you."/>
        </h2>
        <p className="lead text-muted py-5">
            <FormattedMessage id="about.description" defaultMessage="I am passionate about building excellent web applications that improves the lives of those around me. I specialize in creating web applications for clients ranging from individuals and small-businesses all the way to large enterprise corporations. What would you do if you had a great web developer available at your fingertips?"/>
        </p>
        <div className="pb-5">
            <a className="button is-primary is-outlined"  href="mailto:allangmr10@gmail.com">
                <FormattedMessage id="about.contact" defaultMessage="Get In Touch"/>
            </a>
        </div>
    </div>
  );
};

export default Career;