import React from 'react'
import  './Footer.css'
import { FormattedMessage } from 'react-intl'
import SocialIcons from './about/SocialIcons';







const Footer = () => {


    return (
        <>
        <section className="text-center container-fluid  four responsive-flex">
        <footer>
            <div className="container-fluid">

                <h5  className="py-4">
                <FormattedMessage id="footer.slogan" defaultMessage="Living, learning, & leveling up one day at a time."/>
                </h5>
                <SocialIcons />
                <p className="mb-0 pt-3">
                <FormattedMessage id="footer.brand" defaultMessage="Handcrafted by "/>
                <span className="logo-title">Allan Montilla</span></p>
            </div>
        </footer>
        </section>
        </>
    );
  };
  
  export default Footer;