import React, { Component } from 'react'
import Navbar from './components/Navbar'
import Intro from './components/Intro'
import About from './components/About'
import Proyects from './components/Proyects'
import './App.css'
import Footer from './components/Footer'
export default class App extends Component {


    render() {
        return (
                <div>
                    <div className="container-fluid">
                        <Navbar/>
                        <div className="parents example">
                            <main>
                                <Intro/>
                                <About/>
                                <Proyects/>
                                <Footer/>
                            </main>
                        </div>
                    </div>
                </div>
        )
    }
    
}
