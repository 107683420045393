import React from 'react'
import  './About.css'
import SocialIcons from './about/SocialIcons'
import Details from './about/Details'
import Career from './about/Career'
import Stack from './about/Stack'
import Resume from './Resume'

const About = () => {
    const images_stack = 
    [
        {id: 1, link: '/assets/images/stack/html5.png', alt: 'Domino la tecnología HTML5'},
        {id: 2, link: '/assets/images/stack/css3.png', alt: 'Domino la tecnología CSS'},
        {id: 3, link: '/assets/images/stack/bootstrap.png', alt: 'Domino la tecnología Bootstrap'},
        {id: 4, link: '/assets/images/stack/jquery.jpg', alt: 'Domino la tecnología Jquery'},
        {id: 5, link: '/assets/images/stack/javascript.jpeg', alt: 'Domino la tecnología Javascript'},
        {id: 6, link: '/assets/images/stack/reactjs.png', alt: 'Domino la tecnología Reactjs'},
        {id: 7, link: '/assets/images/stack/vue.png', alt: 'Domino la tecnología Vuejs'},
        {id: 8, link: '/assets/images/stack/php.jpg', alt: 'Domino la tecnología php'},
        {id: 9, link: '/assets/images/stack/laravel.jpg', alt: 'Domino la tecnología laravel'},
        {id: 10, link: '/assets/images/stack/springboot.png', alt: 'Domino la tecnología spring boot'},
        {id: 11, link: '/assets/images/stack/aspnetcore.png', alt: 'Domino la tecnología aspnetcore'},
        {id: 12, link: '/assets/images/stack/microsoftazure.jpg', alt: 'Domino la tecnología microsoft azure'},
        {id: 13, link: '/assets/images/stack/mariadb.png', alt: 'Domino la tecnología mariadb'},
        {id: 14, link: '/assets/images/stack/plsql.png', alt: 'Domino la tecnología oracle pl sql'},
        {id: 15, link: '/assets/images/stack/sqlserver.png', alt: 'Domino la tecnología microsoft sql server'},
        {id: 16, link: '/assets/images/stack/postgresql.png', alt: 'Domino la tecnología postgresql'},
        {id: 17, link: '/assets/images/stack/digitalocean.jpg', alt: 'Domino la tecnología digitalocean'},
        {id: 18, link: '/assets/images/stack/postman.png', alt: 'Domino la tecnología postman'},
        {id: 19, link: '/assets/images/stack/github.png', alt: 'Domino la tecnología github'},
        {id: 20, link: '/assets/images/stack/git.png', alt: 'Domino la tecnología git'},
        {id: 21, link: '/assets/images/stack/sourcetree.png', alt: 'Domino la tecnología sourcetree'},
        {id: 22, link: '/assets/images/stack/wordpress.png', alt: 'Domino la tecnología wordpress'},
        {id: 23, link: '/assets/images/stack/ubuntu.jpg', alt: 'Domino la tecnología ubuntu'}
    ];
    return (
        <section className="pb-5 text-center container-fluid two ">
            <div className="row py-lg-5">
                <div className="col-lg-3 col-md-3 col-sm-12 text-lg-center">
                    <img className="img-fluid py-lg-2"  alt="Foto de Allan Montilla" src="/assets/images/web-developer-allan-montilla.png" height="300" width="300"/>
                    <SocialIcons />
                    <Details />
                    <div className="Resume py-3">
                        <Resume/>
                    </div>
                </div>
                <div className="col-lg-9 col-md-9 col-sm-12  mx-auto py-5">
                    <div className="row">
                        <Career />
                        <div  className="col-lg-6 col-md-6 col-sm-12 stack text-center">
                            <Stack images={images_stack} title="yes" width="50" height="50" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
  };
  
  export default About;
