
import React from 'react';
import { FormattedMessage } from 'react-intl'

const Resume = () => {
  return (
    <>
        <a className="button is-primary is-outlined" href="/assets/pdf/resume-allan-montilla.pdf" download>
            <FormattedMessage id="navbar.resume" defaultMessage="Resume"/>
        </a>
    </>
  );
};

export default Resume;