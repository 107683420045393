import React from 'react'
import  './Proyects.css'
import { FormattedMessage } from 'react-intl'
import ImageDesc from './proyects/ImageDesc'






const Proyects = () => {

    const images_proyects = 
    [
        {id: 1, link: '/assets/images/infoapp-allan-montilla.PNG', alt: 'Aplicación para Compartir Información Institucional'}
    ];


    return (
        <>
        <section className="py-5 text-center container-fluid  three">
            <div className="row margin-last">
                <div className="col-lg-12 col-md-12 col-sm-12 mx-auto">
                <h2 className="py-5 subtitle">
                    <FormattedMessage id="proyects.subtitle" defaultMessage="Some Things I’ve Built"/>
                </h2>
                </div>
                <div className="col-lg-12 col-md-12 col-sm-12">
                    <ImageDesc images={images_proyects}/>
                </div>
            </div>
        </section>
        </>
    );
  };
  
  export default Proyects;