import React  from 'react'
import  './Intro.css'
import { FormattedMessage } from 'react-intl'




const Intro = () => {
    return (
        <section className="pb-5 text-center container-fluid  one responsive-flex">
        <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 mx-auto">
                <h1 className="title-name">Allan Montilla</h1>
                <h2 className="title pb-lg-4">
                    <FormattedMessage id="intro.profession" defaultMessage="Web Developer & Software Engineer"/>
                </h2>
            </div>
        </div>
    </section>
    );
  };
  
  export default Intro;