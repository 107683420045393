import React from 'react';


const SocialIcons = () => {
  return (
        <div className="social-icons  text-lg-center">
            <a  href="mailto:allangmr10@gmail.com">
                <img className="img-fluid d-inline mx-2"  alt="Enlace para enviar un Correo" src="/assets/images/email.png" height="25" width="25"/>
            </a>
            <a  href="https://www.linkedin.com/in/allan-montilla-00a756b3/" target="_blank" rel="noreferrer">
                <img className="img-fluid d-inline mx-2"  alt="Enlace para ingresar al Linkedin" src="/assets/images/linkedin.png" height="23" width="23"/>
            </a>
            <a  href="https://twitter.com/allan_coding" target="_blank" rel="noreferrer">
                <img className="img-fluid d-inline mx-2"   alt="Enlace para ingresar al Twitter"  src="/assets/images/twitter.png" height="23" width="23"/>
            </a>
            <a  href="https://github.com/allangmr" target="_blank" rel="noreferrer">
                <img className="img-fluid d-inline mx-2"   alt="Enlace para ingresar al Github"  src="/assets/images/github.png" height="27" width="27"/>
            </a>
        </div>

  );
};

export default SocialIcons;