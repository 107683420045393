import React, {useState} from 'react';
import MensajesIngles from './../lang/en-US.json'
import MensajesEspanol from './../lang/es-ES.json'
import { IntlProvider } from 'react-intl'
const langContext = React.createContext();

const LangProvider = ({children}) => {
    let localePorDefecto = "";
    let mensajesPorDefecto = "";
    const lang = localStorage.getItem('lang');

    if(lang){
        localePorDefecto = lang;
        if(lang === 'es-ES'){
            mensajesPorDefecto = MensajesEspanol;
        }else if(lang === 'en-US'){
            mensajesPorDefecto = MensajesIngles;
        }else{
            localePorDefecto = 'en-US';
            mensajesPorDefecto = MensajesIngles; 
        }
    }

    const [mensajes, establecerMensajes] = useState(mensajesPorDefecto);
    const [locale, establecerLocale] = useState(localePorDefecto);
    const establecerLenguaje = (lenguaje) =>{
        switch(lenguaje){
            case 'es-ES':
                establecerMensajes(MensajesEspanol)
                establecerLocale('es-ES')
                localStorage.setItem('lang', 'es-ES')
                break;
            case 'en-US':
                establecerMensajes(MensajesIngles)
                establecerLocale('en-US')
                localStorage.setItem('lang', 'en-US')
                break;
            default:
                establecerMensajes(MensajesIngles)
                localStorage.setItem('lang', 'en-US')
                establecerLocale('en-US')
        }
    }
    if(locale ===""){
        establecerMensajes(MensajesIngles)
        localStorage.setItem('lang', 'en-US')
        establecerLocale('en-US')
    }

    return (  
        <langContext.Provider value={{establecerLenguaje: establecerLenguaje }}>
            <IntlProvider locale ={locale} messages={mensajes}>
                {children}
            </IntlProvider>
        </langContext.Provider>
    );
}
 
export  {LangProvider, langContext};