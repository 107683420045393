import React from 'react';
import { FormattedMessage } from 'react-intl';
import './Stack.css'

const Stack = (props) => {
    const images = props.images;
    const listItems = images.map((image) =>
        <img src={image.link} alt={image.alt} width={props.width} height={props.height} className="stack_img"  key={image.id}/>
    );

  return (
    <div >
        {props.title === "yes" &&
            <h2 className="py-3 subtitle">                
                <FormattedMessage id="about.stack.title" defaultMessage="My Tech Stack"/>
            </h2>
        }
        <div className="list-items">
            {listItems}
        </div>
    </div>

  );
};

export default Stack;